import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

class CustomUploadAdapter {
  constructor( loader, path ) {
    // The file loader instance to use during the upload.
    this.loader = loader
    this.path = path
  }

  upload() {
    const { loader, path } = this

    return loader.file.then( (file) => new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('path', path)

      axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/firebase/storage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.data.error) {
          reject(response.data.error.message)
        } else {
          resolve({ default: response.data.url })
        }
      }).catch((error) => {
        console.log(error)
      })
    }))
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
  }
}

export default CustomUploadAdapter
