<script setup>

import { defineEmits, defineProps, onMounted, ref } from 'vue'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import CustomUploadAdapter from './custom-upload-adapter'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  hideToolbar: {
    type: Boolean,
    default: false
  },
  modelValue: String,
  path: {
    type: String,
    default: 'tmps'
  }
})

const editorData = ref('')
const editorConfig = {
  mediaEmbed: {
    previewsInData: true
  }
}

onMounted(() => {
  editorData.value = props.modelValue || ''
})

const onEditorInput = (eventInfo) => {
  emit('update:modelValue', eventInfo)
}

const onEditorReady = (editor) => {
  editor.ui.getEditableElement().parentElement.insertBefore(
    editor.ui.view.toolbar.element,
    editor.ui.getEditableElement()
  )

  if (props.hideToolbar) {
    editor.ui.view.toolbar.element.style.display = 'none'
  }

  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
    return new CustomUploadAdapter( loader, props.path )
  }
}

</script>

<template>
  <div id="editor">
    <ckeditor
      v-model="editorData"
      :editor="DecoupledEditor"
      :config="editorConfig"
      :disabled="props.disabled"
      @input="onEditorInput"
      @ready="onEditorReady"
    />
  </div>
</template>

<style lang="scss" scoped>
#editor::v-deep {
  .ck.ck-content.ck-editor__editable { min-height: 300px; }
}

</style>
